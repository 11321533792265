.App {
  width: 100%;
  height: 84vh;
  margin: 0 auto;
  color: lightgrey;
  display: flex;
  flex-direction: column;
}

hr {
  border-color: rgb(1, 200, 200);
  height: 5px;
  background-color: rgb(1, 200, 200);
}


.dont-click {
  top: 0.5rem;
  right: 1rem;
  position: absolute;
  width: max-content;
  cursor: pointer;
  color: rgb(299, 1, 1);
  z-index: 1;
}

.dont-click-fix {
  top: 0.5rem;
  right: 1rem;
  position: absolute;
  width: max-content;
  cursor: pointer;
  color: rgb(1, 200, 200);
  z-index: 1;
}

@media only screen and (max-width: 768px) {
  .dont-click {
    top: 1rem;
    right: 1rem;
    position: absolute;
    width: max-content;
    cursor: pointer;
    color: rgb(299, 1, 1);
  }

  .dont-click-fix {
    top: 1rem;
    right: 1rem;
    position: absolute;
    width: max-content;
    cursor: pointer;
    color: rgb(1, 200, 200);
  }
}